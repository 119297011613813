<script setup>
const emit = defineEmits(["click", "mouseover", "mouseout"]);
import { useToggle } from "@vueuse/core";

const props = defineProps({
  heading: String,
  body: String,
  callToAction: [Object, null],
  index: {
    type: Number,
    default: 0,
  },
  customClass: {
    type: String,
    default: "",
  },
});

const content = ref(null);

const [isOpen, toggle] = useToggle(false);

watch(isOpen, () => {
  if (isOpen.value) {
    content.value.style.maxHeight = content.value.scrollHeight + "px";
  } else {
    content.value.style.maxHeight = "0px";
  }
});
</script>

<template>
  <div
    class="accordion cursor-pointer p-4 md:p-[var(--grid-gap)]"
    :class="[{ 'is-open': isOpen }, `${customClass}`]"
    @mouseover.stop="(e) => emit('mouseover', heading)"
    @mouseout.stop="(e) => emit('mouseout', heading)"
    @click="toggle()"
  >
    <ui-tag-corners />
    <div class="accordion__header" :class="[`${customClass}`]">
      <div class="accordion-trigger">
        <ui-font-text type="s-bold">
          {{ heading }}
        </ui-font-text>
      </div>
      <span class="accordion__icon">
        <span class="accordion__icon-line"></span>
        <span class="accordion__icon-line"></span>
      </span>
    </div>
    <div class="accordion__content" ref="content">
      <div
        class="accordion__content-inner flex flex-col gap-spacing-default-desktop pr-[calc(var(--grid-gap)*2)] pt-[12px]"
        :class="[`${customClass}`]"
      >
        <ui-font-text type="s">
          <span
            class="accordion__content-inner-body"
            v-if="body"
            v-html="body"
          />
        </ui-font-text>
        <ui-call-to-action v-if="callToAction" :cta="callToAction">
        </ui-call-to-action>
      </div>
    </div>
  </div>
</template>

<style lang="postcss">
.accordion {
  position: relative;
  border-top: 1px solid hsla(var(--theme-text) / 0.3);

  transition:
    opacity 0.5s var(--ease-in-cubic) 1s,
    transform 0.5s var(--ease-in-cubic) 1s;

  .tag-corners-background {
    opacity: 0;
  }

  &.visible {
    opacity: 1;
    transform: translateX(0);

    .tag-corners-border {
      opacity: 0.4;
    }
  }

  &.opaque {
    opacity: 0.4;
    transition: opacity 0.5s ease;
  }
  &.hidden {
    opacity: 0;
    transition-delay: 0s !important;
    transition: none;
    transform: translateX(-2rem);
  }

  &:hover {
    .tag-corners-border {
      opacity: 1;
    }
  }

  &.is-open {
    .tag-corners-border {
      opacity: 1;
    }
    .tag-corners-background {
      opacity: 0.1;
    }
    .accordion__icon-line {
      &:nth-child(2) {
        transform: translate(-50%, -50%) rotate(0deg);
      }
    }
  }

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.visible {
      opacity: 1;
      transition: opacity 0.5s ease;
    }

    &.opaque {
      opacity: 0.4;
      transition: opacity 0.5s ease;
    }
  }

  &-trigger {
    background: none;
    border: 0;
    padding: units(2) 0;
    width: 100%;
    text-align: left;
  }

  &__icon {
    display: flex;
    width: 1em;
    height: 1em;
    position: relative;
    cursor: pointer;
  }

  &__icon-line {
    height: 1px;
    width: 100%;
    background: currentColor;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center;
    transition: transform 0.3s ease;

    &:nth-child(2) {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }

  &__content {
    max-height: 0;
    overflow: hidden;
    transition: 0.3s ease-in-out;
  }

  &__content-inner {
    padding-right: units(4);
    padding-bottom: units(2);

    &.visible {
      opacity: 1;
      transition: opacity 0.5s ease;
    }

    &.opaque {
      opacity: 0.4;
      transition: opacity 0.5s ease;
    }

    .tag-list {
      margin-top: units(2);
    }

    .custom-link {
      display: block;
      margin-top: units(2);
    }

    &-body {
      ul {
        list-style: square;
        list-style-position: outside;
        padding-left: 2em;
        li {
          ul {
            margin-inline-start: 1em;
            list-style-position: outside;
            padding-left: 1em;
            /* list-style: inside; */
          }
        }
      }
      ol {
        list-style-position: outside;
        padding-left: 1em;
        li {
          ol {
            list-style-position: outside;
            margin-inline-start: 1em;
            list-style: lower-alpha;
            padding-left: 1em;
            li {
              ol {
                list-style-position: outside;
                list-style: lower-roman;
                padding-left: 1em;
              }
            }
          }
        }
      }
    }
  }

  &-hidden {
    position: absolute;
    top: 0;
    visibility: hidden;
  }
}
</style>
