<script setup>
const props = defineProps({
  number: Number,
  suffix: String,
  label: String,
  body: String,
});

const suffixedNumber = computed(() => {
  if (props.suffix) {
    return formatSuffix(props.number, props.suffix);
  }
  return props.number;
});
</script>

<template>
  <article
    class="number-card relative flex pr-[var(--grid-gap)] md:aspect-square"
  >
    <div
      class="content flex h-full flex-col justify-between pb-spacing-default md:pb-0"
    >
      <div
        class="heading flex h-full flex-col justify-between gap-spacing-gap md:gap-spacing-default"
      >
        <ui-font-text type="xxl">{{ suffixedNumber }}</ui-font-text>
        <div
          class="content-text mt-1/2 flex h-1/2 flex-col justify-between gap-[var(--grid-gap)]"
        >
          <ui-font-text type="body" class="!font-bold">{{
            label
          }}</ui-font-text>
          <ui-font-text type="body" class="">{{ body }}</ui-font-text>
        </div>
      </div>
    </div>
  </article>
</template>

<style>
.number-card::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: white;
  opacity: 0.25;
  transition: all 0.15s ease-in-out;
  z-index: 1;
  @media (min-width: 768px) {
    width: 1px;
    height: 100%;
  }
}
</style>
