<script setup>
const props = defineProps({
  index: Number,
  url: String,
  teaserTitle: String,
  teaserDescription: String,
  theme: String,
});

// Array of available Lottie file paths
const lottieFiles = [
  "/lottie/card-dots-1.json",
  "/lottie/card-dots-2.json",
  "/lottie/card-dots-3.json",
  "/lottie/card-dots-4.json",
];

const currentLottie = ref(null);
const currentTheme = ref(null);

// Function to get a random Lottie file index
function getRandomLottie(excludeIndex) {
  const availableIndices = lottieFiles
    .map((_, i) => i)
    .filter((i) => i !== excludeIndex);
  const randomIndex = Math.floor(Math.random() * availableIndices.length);
  return availableIndices[randomIndex];
}
const hoverTheme = ref(null);
onMounted(() => {
  const { getCurrentTheme, getCorrespondingCSS } = useTheme();
  currentTheme.value = getCurrentTheme();
  hoverTheme.value = getCorrespondingCSS(props.theme);

  // Set the initial Lottie file, excluding the previous index
  if (props.index === 0) {
    currentLottie.value = getRandomLottie(-1); // No exclusion for the first card
  } else {
    const previousLottieIndex = currentLottie.value;
    currentLottie.value = getRandomLottie(previousLottieIndex);
  }
});

const currentHoverTheme = computed(() => {
  return hoverTheme.value ? `var(${hoverTheme.value})` : currentTheme.value;
});
</script>

<template>
  <article
    :style="`--hover-theme: ${currentHoverTheme}`"
    class="hub-card relative flex aspect-[5/6]"
  >
    <nuxt-link :to="url" target="_self">
      <div
        class="background-color absolute left-0 top-0 h-full w-full hover:bg-[var(--hover-theme)] hover:opacity-10"
      ></div>
      <div
        class="icon-animation absolute left-0 top-0 h-[48px] w-[48px] md:h-[64px] md:w-[64px]"
      >
        <component-common-lottie
          :lottie="lottieFiles[currentLottie]"
          :loop="true"
        />
      </div>
      <div
        class="content flex h-full flex-col justify-between pl-[4px] pr-[12px]"
      >
        <div class="heading mb-auto mt-[50%] flex flex-col gap-spacing-default">
          <ui-font-text type="m" class="!font-bold text-white">{{
            teaserTitle
          }}</ui-font-text>
          <ui-font-text type="body" class="text-light-grey">{{
            teaserDescription
          }}</ui-font-text>
        </div>
        <ui-font-text type="s-bold" class="">{{
          formatCTALabel("Read more")
        }}</ui-font-text>
      </div>
    </nuxt-link>
  </article>
</template>

<style>
.hub-card {
  --line-opacity: 0.25;
}
.hub-card:hover {
  --line-opacity: 1;
}
.hub-card::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background-color: var(--hover-theme);
  opacity: var(--line-opacity);
  transition: all 0.15s ease-in-out;
  z-index: 1;
}
</style>
