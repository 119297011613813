<script setup>
const props = defineProps({
  heading: String,
  isGrid: Boolean,
  people: Array,
});
const noumberOfItems = computed(() => props.people.length);
const { isMobileOrTablet } = useDevice();
const { width: innerWidth } = useWindowSize();

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet;
  return innerWidth.value < 900;
});
const slideWidth = computed(() => {
  return !isMobile.value
    ? "var(--cols-3)"
    : "calc(var(--cols-4) - var(--grid-gap))";
});

const shuffledItems = ref(
  [...props.people].sort((a, b) => 0.5 - Math.random()),
);
</script>

<template>
  <section class="people-grid">
    <ui-bleed>
      <ui-font-text type="h2" class="mb-10">
        {{ heading }}
      </ui-font-text>
    </ui-bleed>
    <client-only>
      <ul
        v-if="isGrid"
        :class="{
          'px-bleed': !isMobile,
        }"
        class="grid gap-[var(--grid-gap)] md:grid-cols-2 lg:grid-cols-4"
      >
        <li v-for="item in shuffledItems" class="shrink-0">
          <ui-bleed v-if="isMobile">
            <component-element-people-card
              v-bind="item"
              class="w-[var(--cols-4)] transition-all md:w-[var(--cols-3)]"
            />
          </ui-bleed>
          <component-element-people-card
            v-else
            v-bind="item"
            class="w-[var(--cols-4)] transition-all md:w-[var(--cols-3)]"
          />
        </li>
      </ul>
      <div v-else class="people-carousel">
        <component-element-embla-carousel
          :slide-width="slideWidth"
          :has-navigation="people.length > 4"
          type="case"
        >
          <component-element-people-card
            v-for="(item, index) in shuffledItems"
            :key="index"
            v-bind="item"
            class="embla__slide h-full min-w-0"
            :class="{ 'ml-[var(--grid-gap)]': index !== 0 }"
          />
        </component-element-embla-carousel>
      </div>
    </client-only>
  </section>
</template>
