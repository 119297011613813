<script setup>
const props = defineProps({
  heading: String,
  body: String,
  items: Array,
  image: Object,
  isHalf: Boolean,
});
const accordion = ref(null);

const { isMobileOrTablet } = useDevice();
const { width: innerWidth } = useWindowSize();

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet;
  return innerWidth.value < 900;
});

const selectedTitle = ref(null);
const handleMouseover = (e) => {
  if (isMobile.value) return;
  selectedTitle.value = e;
};
const handleMouseout = (e) => {
  if (isMobile.value) return;

  selectedTitle.value = null;
};
</script>

<template>
  <div :class="['accordion-list', { 'has-image': image }]">
    <div class="flex flex-col gap-spacing-gap md:gap-spacing-gap-desktop">
      <ui-font-text
        type="l"
        :class="[!isHalf ? 'md:ml-[var(--push-6)]' : '']"
        >{{ heading }}</ui-font-text
      >
      <ui-font-text
        type="body"
        :class="[!isHalf ? 'md:ml-[var(--push-6)]' : '']"
        >{{ body }}</ui-font-text
      >
      <div
        class="accordion-wrapper flex flex-col gap-[var(--grid-gap)] md:flex-row"
      >
        <div
          class="media-wrapper aspect-square w-full shrink-0 md:aspect-[7/6] md:w-[var(--cols-6)]"
          v-if="image"
        >
          <component-common-media
            class="h-full w-full object-cover"
            :media="image"
          ></component-common-media>
        </div>
        <div
          class="accordion-items"
          :class="{
            'md:ml-[var(--push-6)]': !image && !isHalf,
          }"
        >
          <div
            class="accordion-list__blocks w-full md:w-[var(--cols-5)]"
            :class="{ 'has-images': !!image }"
          >
            <!-- <div class="accordion-list__block" v-if="!isHalf && image">
              <div class="accordion-list__images" v-if="image">
                <component-common-media v-bind="{ data: { ...image } }" />
              </div>
            </div> -->

            <div class="accordion-list__block" ref="accordion">
              <div class="accordion-list__list">
                <component-element-accordion-item
                  v-for="(item, index) in items"
                  :key="item.id"
                  v-bind="{
                    ...item,
                    index,
                    customClass:
                      selectedTitle == null
                        ? 'visible'
                        : selectedTitle === item.heading
                          ? 'visible'
                          : 'opaque',
                  }"
                  @mouseover="handleMouseover"
                  @mouseout="handleMouseout"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss">
.accordion-list {
  overflow: hidden;

  .heading {
    opacity: 1;

    @media (min-width: 768px) {
      max-width: 50vw;
    }
  }

  &.has-images {
    background: var(--black);
    color: var(--off-white);

    .transition-linear-mask__bg {
      background: var(--black);
    }
  }

  &__blocks {
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    margin-top: units(4);

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: flex-start;
    }
  }

  &__block {
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
      width: cols(6);
    }

    &:nth-child(2) {
      @media (min-width: 768px) {
        margin-left: var(--grid-gap);
        width: cols(5);
      }
    }

    &:has(.accordion-list__intro),
    &:has(.accordion-list__images) {
      & + .accordion-list__block {
        margin-top: units(4);

        @media (min-width: 768px) {
          margin-top: 0;
        }
      }
    }
  }

  &__sub-heading {
    margin-bottom: units(2);
  }

  &__list {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__intro {
    display: flex;
    flex-direction: column;

    & + .accordion-list__images {
      margin-top: units(4);
    }

    .heading {
      margin-bottom: units(4);
    }
  }

  &__body {
    display: flex;
    flex-direction: column;

    ul {
      list-style: square;
      list-style-position: outside;
      padding-left: 2em;
      li {
        ul {
          margin-inline-start: 1em;
          list-style-position: outside;
          padding-left: 1em;
          /* list-style: inside; */
        }
      }
    }
    ol {
      list-style-position: outside;
      padding-left: 1em;
      li {
        ol {
          list-style-position: outside;
          margin-inline-start: 1em;
          list-style: lower-alpha;
          padding-left: 1em;
          li {
            ol {
              list-style-position: outside;
              list-style: lower-roman;
              padding-left: 1em;
            }
          }
        }
      }
    }

    @media (min-width: 768px) {
      max-width: cols(5);
    }
  }

  &__images {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--grid-gap);
  }

  &__image-tile {
    background: hsl(var(--color-off-white));
    width: 100%;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    .image {
      width: 50%;
      height: auto;
    }
  }
}
</style>
