<script setup>
const props = defineProps({
  shouldRandomize: {
    type: Boolean,
    default: false,
  },
});
const { getRandomTheme, getCurrentTheme } = useTheme();
const randomTheme = ref(null);
const currentTheme = ref(null);

onMounted(() => {
  currentTheme.value = getCurrentTheme();
  randomTheme.value = getRandomTheme([
    "white",
    "cappuccino",
    "mustard",
    "plum",
    "mint",
    "grey",
    "purple",
    "light-grey",
  ]);
});
</script>
<template>
  <div
    class="profile-placeholder"
    :class="`bg-${shouldRandomize ? randomTheme : currentTheme}-1 text-${shouldRandomize ? randomTheme : currentTheme}-text`"
  >
    <ui-icons-smiley class="profile-placeholder__smiley" />
  </div>
</template>

<style>
.profile-placeholder {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-placeholder__smiley {
  margin-top: 15%;
  width: 35%;
}
</style>
