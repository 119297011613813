<script setup>
const props = defineProps({
  media: Object,
  halfWidth: Boolean,
  offsetRight: Boolean,
});

const { isMobileOrTablet } = useDevice();
const { width: innerWidth } = useWindowSize();

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet;
  return innerWidth.value < 900;
});
</script>

<template>
  <section class="media">
    <ui-bleed>
      <ui-half-width
        :half-width="halfWidth && !isMobile"
        :class="{
          '!w-[var(--cols-5)]': halfWidth && !isMobile,
          'mr-[var(--push-1)] !w-[var(--cols-11)]': offsetRight && !isMobile,
        }"
      >
        <component-common-media
          :media="media"
          class="aspect-square h-full w-full overflow-hidden"
          :class="{
            'md:aspect-[9/4]': !halfWidth,
            'md:aspect-[9/5]': halfWidth,
          }"
        />
      </ui-half-width>
    </ui-bleed>
  </section>
</template>
