<script setup>
const props = defineProps({
  heading: String,
  summary: String,
  video: Object,
  poster: Object,
});
const currentTheme = ref(null);
onMounted(() => {
  const { getCurrentTheme } = useTheme();
  currentTheme.value = getCurrentTheme();
});
props.poster ? provide("customPoster", props.poster[0]?.attributes) : null;
</script>

<template>
  <article class="video-card relative">
    <ui-tag-corners />
    <div class="video-card__inner p-spacing-default">
      <ui-font-text
        type="xs"
        class="mb-[12px]"
        :style="`color: var(--${currentTheme}-3)`"
        >Video</ui-font-text
      >

      <div class="video-card__media-container">
        <component-common-media
          class="mb-[20px] aspect-[6/7]"
          :media="video"
        ></component-common-media>

        <!--
        <button class="video-card__play-btn">
          <icons-play />
        </button>
        -->
      </div>

      <div class="video-card__content">
        <ui-font-text type="m" class="mb-[12px]">
          {{ heading }}
        </ui-font-text>

        <ui-font-text type="body">{{ summary }}</ui-font-text>
      </div>
    </div>
  </article>
</template>
