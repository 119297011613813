<script setup>
const props = defineProps({
  hubs: Array,
});

const { isMobileOrTablet } = useDevice();
const { width: innerWidth } = useWindowSize();

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet;
  return innerWidth.value < 900;
});
const slideWidth = computed(() => {
  if (innerWidth.value > 768 && innerWidth.value < 900) return "var(--cols-6)";
  if (innerWidth.value < 768) return "var(--cols-4)";
  else return "calc(var(--cols-4) - var(--grid-gap))";
});
</script>

<template>
  <section class="hubs-list">
    <div class="mobile-carousel" v-if="isMobile">
      <component-element-embla-carousel :slide-width="slideWidth">
        <component-element-hub-card
          class="embla__slide h-full min-w-0"
          v-for="(hub, index) in hubs"
          :class="{ 'ml-[var(--grid-gap)]': index !== 0 }"
          v-bind="hub"
          :index="index"
        />
      </component-element-embla-carousel>
    </div>
    <div
      v-else
      class="relative grid w-full gap-x-[var(--grid-gap)] gap-y-12 px-bleed lg:grid-cols-3 xl:grid-cols-4"
    >
      <div v-for="(hub, index) in hubs" :key="index">
        <component-element-hub-card
          v-bind="hub"
          :index="index"
          class="w-[var(--cols-4)] xl:w-[var(--cols-3)]"
        />
      </div>
    </div>
  </section>
</template>
