<script setup>
const props = defineProps({
  heading: String,
  body: String,
  media: Object,
});

const { isMobileOrTablet } = useDevice();
const { width: innerWidth } = useWindowSize();

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet;
  return innerWidth.value < 900;
});

const maxLength = 150;

const isBodyTooLong = computed(
  () => props.body && props.body.length > maxLength,
);

const isExpanded = ref(false);
const displayFullBody = ref(false);

const truncatedBody = computed(() => {
  if (displayFullBody.value || !isBodyTooLong.value) {
    return props.body;
  }
  return props.body.slice(0, maxLength) + "...";
});

const toggleExpand = async () => {
  if (isExpanded.value) {
    isExpanded.value = false;
    await nextTick();
    setTimeout(() => {
      displayFullBody.value = false;
    }, 300); // Match the CSS transition duration (0.3s)
  } else {
    displayFullBody.value = true;
    await nextTick();
    isExpanded.value = true;
  }
};

const currentTheme = ref(null);
onMounted(() => {
  const { getCurrentTheme } = useTheme();
  currentTheme.value = getCurrentTheme();
});
</script>

<template>
  <article class="case-card relative lg:w-[var(--cols-6)]">
    <ui-tag-corners />
    <div
      class="content flex flex-col justify-between p-spacing-default lg:flex-row-reverse lg:gap-spacing-gap"
    >
      <ui-font-text
        v-if="isMobile"
        type="xs"
        class="mb-[12px]"
        :style="`color: var(--${currentTheme}-3)`"
        >Case</ui-font-text
      >

      <component-common-media
        class="mb-spacing-default-desktop aspect-square h-full w-full overflow-hidden object-cover lg:mb-0 lg:aspect-[7/10] lg:h-auto lg:w-[50%] lg:shrink-0"
        :media="media"
      ></component-common-media>

      <div class="text-content lg:flex lg:flex-col">
        <ui-font-text
          v-if="!isMobile"
          type="xs"
          class="mb-[12px]"
          :style="`color: var(--${currentTheme}-3)`"
          >Case</ui-font-text
        >
        <ui-font-text
          :type="isMobile ? 'm' : 'l'"
          class="mb-spacing-gap-desktop"
        >
          {{ heading }}
        </ui-font-text>

        <ui-font-text
          type="body"
          class="card-body"
          :class="{
            'mt-auto': !isMobile,
            'mb-spacing-gap': isMobile && isBodyTooLong,
            collapsed: !isExpanded && isBodyTooLong,
            expanded: isExpanded,
          }"
          >{{ isMobile ? truncatedBody : body }}</ui-font-text
        >

        <ui-font-text
          v-if="isMobile && isBodyTooLong"
          type="s-bold"
          class="text-primary mt-9 cursor-pointer"
          @click="toggleExpand"
        >
          {{
            isExpanded
              ? formatCTALabel("Read less")
              : formatCTALabel("Read more")
          }}
        </ui-font-text>
      </div>
    </div>
  </article>
</template>

<style scoped>
@media (max-width: 768px) {
  .card-body {
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    max-height: 6em;
    position: relative;
  }
  .card-body.expanded {
    max-height: 40em;
    transition: max-height 0.3s ease-in-out;
  }
}
</style>
