<script setup>
const props = defineProps({
  trumpet: String,
  heading: String,
  body: String,
  callToAction: [Object, null],
  media: [Object, null],
  layout: {
    type: String,
    default: "vertical",
  },
});
const { isMobileOrTablet } = useDevice();
const { width: innerWidth } = useWindowSize();

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet;
  return innerWidth.value < 900;
});
const layouts = ref({
  vertical: "flex-col md:flex-col",
  verticalReversed: "flex-row-reverse md:flex-col-reverse",
  split: "flex-col md:flex-row",
  splitReversed: "flex-col-reverse md:flex-row-reverse",
});
const contentLayout = ref({
  vertical:
    "content-wrapper relative w-full p-spacing-default md:grid md:grid-cols-2 md:gap-4 md:justify-between md:p-spacing-default-desktop",
  verticalReversed:
    "content-wrapper relative w-full p-spacing-default md:flex md:flex-col md:justify-between md:p-spacing-default-desktop",
  split:
    "content-wrapper relative w-full p-spacing-default md:flex md:flex-col md:justify-between md:p-spacing-default-desktop",
  splitReversed:
    "content-wrapper relative w-full p-spacing-default md:flex md:flex-col md:justify-between md:p-spacing-default-desktop",
});
const mediaLayouts = ref({
  vertical:
    "flex-row md:flex-col aspect-square md:aspect-video overflow-hidden",
  verticalReversed:
    "flex-row-reverse md:flex-col-reverse aspect-square md:aspect-video overflow-hidden",
  split: "w-full shrink-0 aspect-square md:aspect-[91/85] md:w-[var(--cols-6)]",
  splitReversed:
    "flex-col-reverse md:flex-row-reverse  w-full shrink-0 aspect-square md:aspect-[91/85] md:w-[var(--cols-6)]",
});
const currentTheme = ref(null);
onMounted(() => {
  const { getCurrentTheme } = useTheme();
  currentTheme.value = getCurrentTheme();
});
</script>

<template>
  <div class="content flex gap-[var(--grid-gap)]" :class="layouts[layout]">
    <div class="media-wrapper" :class="mediaLayouts[layout]">
      <component-common-media
        class="h-full w-full object-cover"
        v-if="media"
        :media="media"
      ></component-common-media>
    </div>
    <div :class="contentLayout[layout]">
      <ui-tag-corners />

      <div
        class="heading"
        :class="{
          'md:w-[var(--cols-5)]':
            layout == 'vertical' || layout == 'verticalReversed',
        }"
      >
        <ui-font-text
          v-if="trumpet"
          type="xs"
          class="mb-spacing-small md:mb-spacing-small-desktop"
          :style="`color: var(--${currentTheme}-3)`"
          >{{ trumpet }}</ui-font-text
        >
        <ui-font-text
          :type="isMobile ? 'l' : 'xxl'"
          class="md:md-0 mb-spacing-default-desktop"
          >{{ heading }}</ui-font-text
        >
      </div>
      <div class="body md:pr-[calc(var(--cols-1)-24px+var(--grid-gap))]">
        <ui-font-text type="body" :class="{ 'mb-10 md:mb-20': callToAction }"
          >{{ body }}
        </ui-font-text>
        <div v-if="callToAction" class="cta-wrapper">
          <ui-call-to-action :cta="callToAction" :has-background="true" />
        </div>
      </div>
    </div>
  </div>
</template>
