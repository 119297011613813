<script setup>
import emblaCarouselVue from "embla-carousel-vue";
import { WheelGesturesPlugin } from "embla-carousel-wheel-gestures";
const props = defineProps({
  type: String,
  hasNavigation: {
    type: Boolean,
    default: true,
  },
  loop: {
    type: Boolean,
    default: false,
  },
  align: {
    type: String,
    default: "start",
  },
  slideWidth: {
    type: String,
    default: "var(--cols-3)",
  },
  slideSpacing: {
    type: String,
    default: "var(--grid-gap)",
  },
});

const { isMobileOrTablet } = useDevice();
const { width: innerWidth } = useWindowSize();

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet;
  return innerWidth.value < 900;
});

const [emblaRef, emblaApi] = emblaCarouselVue(
  {
    loop: props.loop,
    align: props.align,
  },
  [WheelGesturesPlugin()],
  //   [Fade()]
);
const emblaStyle = computed(() => {
  return {
    "--slides-per-view": props.slidesPerView,
    "--slide-spacing": props.slideSpacing,
    "--slide-size": props.slideWidth,
  };
});
const slidesInView = ref([]);
const reachedBeginning = ref(true);
const reachedEnd = ref(false);

onMounted(() => {
  if (emblaApi.value) {
    slidesInView.value = emblaApi.value.slidesInView();

    emblaApi.value.on("slidesInView", (emblaApi) => {
      slidesInView.value = emblaApi.slidesInView();
    });

    emblaApi.value.on("select", (emblaApi) => {
      reachedBeginning.value = !emblaApi.canScrollPrev();
      reachedEnd.value = !emblaApi.canScrollNext();
    });
    emblaApi.value.on("init", (emblaApi) => {
      reachedBeginning.value = !emblaApi.canScrollPrev();
      reachedEnd.value = !emblaApi.canScrollNext();
    });
    emblaApi.value.on("reinit", (emblaApi) => {
      reachedBeginning.value = !emblaApi.canScrollPrev();
      reachedEnd.value = !emblaApi.canScrollNext();
    });
  }
});

function shouldRenderSlide(index) {
  return !slidesInView.value.length || slidesInView.value.includes(index);
}

const prevSlide = () => {
  if (emblaApi.value) {
    return emblaApi.value.scrollPrev();
  }
};
const nextSlide = () => {
  if (emblaApi.value) {
    return emblaApi.value.scrollNext();
  }
};
</script>

<template>
  <ui-bleed>
    <div class="embla relative h-full" ref="emblaRef" :style="emblaStyle">
      <div class="embla__container flex h-full touch-pan-y touch-pinch-zoom">
        <slot />
      </div>
      <div
        v-if="!isMobile && hasNavigation"
        class="pointer-events-none absolute left-0 top-0 z-[1] flex h-full w-full flex-row items-center justify-between"
      >
        <button
          aria-label="Previous slide"
          title="Previous slide"
          class="pointer-events-auto h-auto w-[48px] p-4 transition-opacity"
          :class="[
            { '!pointer-events-none opacity-0': reachedBeginning },
            {
              'border border-dark-grey-1 bg-dark-grey': type === 'video',
              'bg-[rgba(31,31,35,0.25)] backdrop-blur-[32px]': type === 'case',
            },
          ]"
          @click="prevSlide"
        >
          <ui-icons-arrow-nav-left />
        </button>
        <button
          aria-label="Next slide"
          title="Next slide"
          :class="[
            { '!pointer-events-none opacity-0': reachedEnd },

            {
              'border border-dark-grey-1 bg-dark-grey': type === 'video',
              'bg-[rgba(31,31,35,0.25)] backdrop-blur-[32px]': type === 'case',
            },
          ]"
          class="pointer-events-auto h-auto w-[48px] p-4 transition-opacity"
          @click="nextSlide"
        >
          <ui-icons-arrow-nav-right />
        </button>
      </div>
    </div>
  </ui-bleed>
</template>
<style>
.embla {
  --slide-height: 100%;
}

.embla__slide {
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
}
</style>
