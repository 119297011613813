<script setup>
const props = defineProps({
  trumpet: String,
  highlightedText: String,
  halfWidth: Boolean,
});

const { isMobileOrTablet } = useDevice();
const { width: innerWidth } = useWindowSize();

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet;
  return innerWidth.value < 900;
});
</script>

<template>
  <section class="highlighted-text">
    <ui-bleed class="flex flex-col gap-spacing-default">
      <ui-font-text type="xs">{{ trumpet }}</ui-font-text>
      <client-only>
        <component-element-highlighted-text
          :type="isMobile ? 'l' : 'xl'"
          :highlighted-text="highlightedText"
          :has-scramble-effect="false"
      /></client-only>
    </ui-bleed>
  </section>
</template>
