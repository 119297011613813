<script setup>
const props = defineProps({
  rte: String,
});

// Define Tailwind Typography classes dynamically
const classes = {
  h1: "prose-h1:font-display prose-h1:text-l prose-h1:font-normal prose-h1:break-words prose-h1:text-white",
  h2: "prose-h2:font-display prose-h2:text-l prose-h2:font-normal prose-h2:break-words prose-h2:text-white",
  h3: "prose-h3:font-display prose-h3:text-l prose-h3:font-normal prose-h3:break-words prose-h3:text-white",
  strong:
    "prose-strong:font-text prose-strong:text-body prose-strong:font-normal prose-strong:text-white prose-strong:break-words",
  body: "prose-p:font-text prose-p:text-body prose-p:font-normal prose-p:text-white prose-p:break-words",
  a: "prose-a:font-text prose-a:text-body prose-a:font-normal prose-a:text-white prose-a:break-words",
  list: "prose-ul:font-text prose-ul:text-body prose-ul:font-normal prose-ul:text-white prose-ul:break-words prose-ul:whitespace-pre-line prose-ul:space-y-4 ",
  listItem:
    "prose-li:font-text prose-li:text-body prose-li:font-normal prose-li:text-white prose-li:break-words prose-li:whitespace-pre-line prose-li:space-y-4",
};

const allClasses = Object.values(classes).join(" ");
</script>

<template>
  <div class="rte prose" v-html="rte" :class="allClasses"></div>
</template>
