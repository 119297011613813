<script setup>
const props = defineProps({
  quote: String,
  body: [String, null],
  callToAction: [Object, null],
  halfWidth: Boolean,
});

const { isMobileOrTablet } = useDevice();
const { width: innerWidth } = useWindowSize();

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet;
  return innerWidth.value < 900;
});
</script>

<template>
  <section class="quote">
    <ui-bleed>
      <ui-half-width :half-width="halfWidth">
        <ui-font-text
          :type="isMobile ? 'l' : 'xl'"
          :class="{
            'mb-spacing-default': body,
            'mb-spacing-gap-desktop': !body && callToAction?.label,
          }"
          ><span class="text-grey-1">/* </span>{{ quote
          }}<span class="text-grey-1"> */</span></ui-font-text
        >
        <ui-font-text
          v-if="body"
          :type="isMobile ? 'xs' : 's'"
          :class="{ 'mb-spacing-gap-desktop': callToAction?.label }"
          >{{ body }}</ui-font-text
        >
        <ui-call-to-action :cta="callToAction" v-if="callToAction?.label" />
      </ui-half-width>
    </ui-bleed>
  </section>
</template>
