<script setup>
const props = defineProps({
  heading: String,
  body: String,
  media: Object,
});

const { isMobileOrTablet } = useDevice();
const { width: innerWidth } = useWindowSize();

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet;
  return innerWidth.value < 900;
});

const isBodyTooLong = computed(() => {
  if (props.body)
    return isMobile.value ? props.body.length > 150 : props.body.length > 500;
});

const isExpanded = ref(false);

const toggleExpand = () => {
  isExpanded.value = !isExpanded.value;
};
const currentTheme = ref(null);
onMounted(() => {
  const { getCurrentTheme } = useTheme();
  currentTheme.value = getCurrentTheme();
});
</script>

<template>
  <article class="case-card relative md:w-[var(--cols-5)]">
    <ui-tag-corners />
    <div class="content flex flex-col justify-between p-spacing-default">
      <ui-font-text
        type="xs"
        class="mb-[12px]"
        :style="`color: var(--${currentTheme}-3)`"
        >Case</ui-font-text
      >

      <component-common-media
        class="mb-spacing-default-desktop aspect-square h-full w-full overflow-hidden object-cover md:aspect-[9/5]"
        :media="media"
      ></component-common-media>

      <div class="text-content">
        <ui-font-text
          :type="isMobile ? 'm' : 'l'"
          class="mb-spacing-gap-desktop"
        >
          {{ heading }}
        </ui-font-text>

        <ui-font-text
          type="body"
          class="card-body"
          :class="{
            'mt-auto': !isMobile,
            'mb-spacing-gap': isBodyTooLong,
            collapsed: !isExpanded && isBodyTooLong,
            expanded: isExpanded,
          }"
          >{{ body }}</ui-font-text
        >
        <ui-call-to-action :button="true" :has-background="false">
          <ui-font-text
            v-if="isMobile && isBodyTooLong"
            type="s-bold"
            class="text-primary cursor-pointer"
            @click="toggleExpand"
          >
            {{
              isExpanded
                ? formatCTALabel("Read less")
                : formatCTALabel("Read more")
            }}
          </ui-font-text>
        </ui-call-to-action>
      </div>
    </div>
  </article>
</template>

<style scoped>
.card-body {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  max-height: 67em;
}
.card-body.expanded {
  -webkit-line-clamp: 100;
  max-height: 40em;
  transition: all 0.3s ease-in-out;
}

@media (min-width: 768px) {
  .card-body {
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    max-height: 67em;
  }
  .card-body.expanded {
    -webkit-line-clamp: 150;
    max-height: 40em;
    transition: all 0.3s ease-in-out;
  }
}
</style>
