<script setup>
const props = defineProps({
  heading: String,
  body: String,
  media: Object,
});
const { isMobileOrTablet } = useDevice();
const { width: innerWidth } = useWindowSize();

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet;
  return innerWidth.value < 900;
});
</script>

<template>
  <section class="hero bg-black relative flex h-[100svh] text-white">
    <ui-bleed
      class="content mt-auto flex w-full flex-col gap-spacing-gap pb-spacing-default md:flex-row md:items-end md:gap-[calc(var(--push-2)+var(--grid-gap))] md:pb-spacing-default-desktop"
    >
      <component-element-highlighted-text
        class="md:w-[var(--cols-4)]"
        :highlighted-text="heading"
        :type="isMobile ? 'xl' : 'xxl'"
        :has-scramble-effect="true"
      />
      <ui-font-text type="s" class="md:w-[var(--cols-5)]">{{
        body
      }}</ui-font-text>
    </ui-bleed>
    <div
      class="background absolute left-0 top-0 z-[var(--z-index-bellow)] h-full w-full"
    >
      <component-common-media
        class="h-full w-full object-cover"
        v-if="media"
        :media="media"
        loading="eager"
      ></component-common-media>
    </div>
  </section>
</template>
