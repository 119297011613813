<script setup>
const props = defineProps({
  heading: String,
  subHeading: String,
  teaserDescription: String,
  title: String,
  where: String,
  date: String,
  startTime: String,
  endTime: String,
  timeZone: String,
  url: String,
  trumpet: String,
  media: Object,
});

const { isMobileOrTablet } = useDevice();
const { width: innerWidth } = useWindowSize();

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet;
  return innerWidth.value < 900;
});

function formatDate(inputString) {
  // Extract year, month, and day from the input string
  const [year, month, day] = inputString.split("T")[0].split("-");

  // Define an array of month names
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Format the date
  const formattedDate = `${parseInt(day)} ${monthNames[parseInt(month) - 1]} ${year}`;

  return formattedDate;
}
</script>
<template>
  <section class="event-teaser">
    <ui-bleed class="flex flex-col gap-spacing-default">
      <div
        class="relative flex flex-col gap-spacing-gap-desktop px-[var(--grid-gap)] py-[var(--grid-gap)] md:flex-row md:gap-[var(--cols-2)] md:py-spacing-gap"
      >
        <ui-tag-corners />
        <div
          class="heading flex flex-col gap-spacing-default md:w-[var(--cols-4)]"
        >
          <ui-font-text type="xs" class="opacity-50">
            {{ trumpet }}
          </ui-font-text>
          <ui-font-text :type="isMobile ? 'l' : 'xxl'">
            {{ title }}
          </ui-font-text>
          <ui-font-text type="body">
            {{ subHeading || teaserDescription }}
          </ui-font-text>
        </div>
        <div
          class="infos-wrapper md:flex md:h-auto md:w-[var(--cols-6)] md:flex-col md:justify-between"
        >
          <div class="infos flex flex-col gap-spacing-default md:flex-row">
            <div class="where md:w-[var(--cols-3)]">
              <ui-font-text type="xs" class="opacity-50"> Where </ui-font-text>
              <component-element-rich-text :rte="where" class="mt-[12px]" />
            </div>
            <div class="when md:w-[var(--cols-3)]">
              <ui-font-text type="xs" class="opacity-50"> When </ui-font-text>
              <ui-font-text type="body" class="mt-[12px]">
                {{ formatDate(date) }}
              </ui-font-text>
              <ui-font-text type="body">
                {{ startTime }} - {{ endTime }}
                <span v-if="timeZone"> ({{ timeZone }})</span>
              </ui-font-text>
            </div>
          </div>
          <div
            class="mt-4 flex items-start md:col-span-2 md:mt-0 md:justify-start"
          >
            <ui-call-to-action
              :cta="{ label: 'Signup', href: url, target: '_self' }"
            ></ui-call-to-action>
          </div>
        </div>
      </div>
      <component-common-media
        v-if="media"
        class="aspect-square h-full w-full overflow-hidden md:aspect-[10/3]"
        :media="media"
      ></component-common-media>
    </ui-bleed>
  </section>
</template>
