<script setup>
const props = defineProps({
  sections: Array,
  isHalf: Boolean,
});

const route = useRoute();
function camelCaseToWords(s) {
  const result = s.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}

const debug = computed(() => useRoute().query.sections !== undefined);
const classes = ref({
  default:
    "sections gap-spacing-section md:gap-spacing-section-desktop md:my-spacing-section-desktop my-spacing-section flex flex-col",
  half: "sections my-6 gap-20 flex flex-col",
});

const sectionsContainer = ref(null);
const { isMobileOrTablet } = useDevice();
const { width: innerWidth } = useWindowSize();

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet;
  return innerWidth.value < 900;
});
const numberOfLotties = ref(1);
onMounted(() => {
  if (route.name !== "what-we-do-slug") return;
  if (isMobile.value) return;
  const { height: containerHeight } =
    sectionsContainer.value.getBoundingClientRect();
  const { height: lottieHeight } = sectionsContainer.value
    .querySelector(".lottie-element")
    .getBoundingClientRect();
  numberOfLotties.value = Math.ceil(containerHeight / lottieHeight);
});
</script>

<template>
  <div
    class="relative"
    :class="classes[isHalf ? 'half' : 'default']"
    ref="sectionsContainer"
  >
    <component-section
      v-for="({ id, type, data }, idx) in sections"
      :data-module-name="debug ? camelCaseToWords(type) : null"
      :class="{ 'debug-module': debug }"
      :type="type"
      :data="data"
      :idx="idx"
      :id="id"
    />
    <div
      v-if="!isMobile && route.name === 'what-we-do-slug'"
      class="dots absolute left-0 top-0 z-[-1] h-full w-screen overflow-hidden opacity-40"
    >
      <component-common-lottie
        v-for="n in numberOfLotties"
        :key="n"
        class="lottie-element h-screen w-auto"
        lottie="/lottie/VerticalDots_03.json"
        :loop="true"
        :width="'100vh'"
      />
    </div>
  </div>
</template>

<style>
.dots {
  /* background-color: aquamarine; */
  mask-image: linear-gradient(-270deg, #1f1f23 0%, rgba(31, 31, 35, 0) 55%);
}

.dots .lottie-animation-container {
  margin: 0 !important;
}
</style>
