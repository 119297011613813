<script setup>
const props = defineProps({
  videos: Array,
});

const noumberOfItems = computed(() => props.videos.length);
const { isMobileOrTablet } = useDevice();
const { width: innerWidth } = useWindowSize();

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet;
  return innerWidth.value < 900;
});
const disableAmount = ref(isMobile.value ? 1 : 4);

const enabled = computed(() =>
  props.videos.length > disableAmount.value ? true : false,
);

const slideWidth = computed(() => {
  return !isMobile.value
    ? "var(--cols-3)"
    : "calc(var(--cols-4) - var(--grid-gap))";
});
</script>

<template>
  <div class="video-carousel">
    <component-element-embla-carousel :slide-width="slideWidth" type="video">
      <component-element-video-card
        class="embla__slide h-full min-w-0"
        :class="{ 'ml-[var(--grid-gap)]': index !== 0 }"
        v-for="(el, index) in videos"
        :key="index"
        :heading="el.heading"
        :summary="el.summary"
        :video="el.video"
        :poster="el.poster"
      />
    </component-element-embla-carousel>
  </div>
</template>
