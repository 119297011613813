<script setup>
const props = defineProps({
  heading: String,
  body: String,
  media: Object,
});

const { isMobileOrTablet } = useDevice();
const { width: innerWidth } = useWindowSize();

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet;
  return innerWidth.value < 900;
});
</script>

<template>
  <section class="case-card" :class="{ 'px-bleed': isMobile }">
    <component-element-half-width-case-card
      :heading="heading"
      :body="body"
      :media="media"
      :half-width="true"
      class="md:ml-[var(--push-6)]"
    />
  </section>
</template>
