<script setup>
const props = defineProps({
  cases: Array,
});

const noumberOfItems = computed(() => props.cases.length);
const { isMobileOrTablet } = useDevice();
const { width: innerWidth } = useWindowSize();

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet;
  return innerWidth.value < 900;
});
const disableAmount = ref(isMobile.value ? 1 : 2);

const enabled = computed(() =>
  props.cases.length > disableAmount.value ? true : false,
);

const slideWidth = computed(() => {
  if (innerWidth.value > 768 && innerWidth.value < 900) return "var(--cols-6)";
  if (innerWidth.value < 768) return "var(--cols-4)";
  else return "var(--cols-6)";
});
</script>

<template>
  <div class="case-carousel">
    <component-element-embla-carousel type="case" :slide-width="slideWidth">
      <component-element-case-card
        class="embla__slide h-full min-w-0"
        v-for="(el, index) in cases"
        :class="{ 'ml-[var(--grid-gap)]': index !== 0 }"
        :key="index"
        :heading="el.heading"
        :body="el.body"
        :media="el.media"
        :index="index"
      />
    </component-element-embla-carousel>
  </div>
</template>
