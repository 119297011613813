<script setup>
const props = defineProps({
  trumpet: String,
  heading: String,
  body: [String, null],
  media: [Object, null],
  layout: String,
  callToAction: [Object, null],
});
</script>

<template>
  <section class="content-section">
    <ui-bleed> <component-element-content v-bind="props" /></ui-bleed>
  </section>
</template>
